import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/furniture/Layout/Layout';

import generateTitle from '../utils/generateTitle';

const NotFoundPage = ({ location }) => {
  const pageTitle = generateTitle('404 Not Found');

  return (
    <Layout location={location} pageTitle={pageTitle}>
      <h1>NOT FOUND</h1>
      <p>You have reached a page that doesn&#39;t exist.</p>
      <p>
        <Link href="/">Back to Homepage</Link>
      </p>
    </Layout>
  );
};

export default NotFoundPage;
